<template>
  <div id="app">
    <Navigation :project_name="project_name" />
    <!-- <Navigation :project_name="project_name" v-if="this.$store.state.user" /> -->
    <!-- <router-view class="container-fluid" :project_name="project_name" /> -->

    <ServiceWorkerForceUpdate
      :alert_message="'The version of this app is outdated. Please Refresh / Reload the page to continue.'" />
    <!-- <ServiceWorkerPleaseUpdate /> -->

    <PWAPrompt />

    <!-- TEMP Breadcrumbs for easy mobile navigation -->
    <div class="mx-2 mb-1">
      <router-link to="/upload">Upload</router-link>
      |
      <router-link to="/email">Email</router-link>
      |
      <router-link to="/sms">SMS</router-link>
      |
      <router-link to="/page">Page</router-link>
      |
      <router-link to="/push-notifications">PushNotifications</router-link>
      |
      <router-link to="/heartbeats">Heartbeats</router-link>
    </div>
    <router-view :project_name="project_name" />
  </div>
</template>

<script>
  import Navigation from '@/components/Navigation.vue';
  // import ServiceWorkerPleaseUpdate from '@/components/ServiceWorkerPleaseUpdate.vue';
  import ServiceWorkerForceUpdate from '@/components/ServiceWorkerForceUpdate.vue';
  import PWAPrompt from '@/components/PWAPrompt.vue';

  export default {
    components: {
      Navigation,
      // ServiceWorkerPleaseUpdate,
      ServiceWorkerForceUpdate,
      PWAPrompt,
    },
    data() {
      return {
        project_name: 'MyWebLab',
      };
    },
    created() {
      let app_name = process.env.VUE_APP_NAME;
      let app_version = process.env.VUE_APP_VERSION;
      let app_git_commit = process.env.VUE_APP_GIT_COMMIT;
      console.log(app_name + '@' + app_version + '@' + app_git_commit);
    },
    methods: {},
  };
</script>

<style>
  /* #app {
    padding-left: 200px;
}
#app.collapsed {
    padding-left: 50px;
} */

  /* body {
  padding-top: 60px;
} */

  /* this is for fixed- navbar */
  body {
    min-height: 75rem;
    padding-top: 4.5rem;
  }
</style>
