import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

import Home from '@/views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER HOME:', store.state.user);
      next();
      // if (store.state.user != null) {
      //   next('/account');
      // } else {
      //   console.log('NOT Authed, redirecting..');
      //   next('/signin');
      // }
    },
  },

  {
    path: '/upload',
    name: 'Upload',
    component: () => import(/* webpackChunkName: "upload" */ '@/views/Upload.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER Upload:', store.state.user);
      next();
      // if (store.state.user != null) {
      //   next('/account');
      // } else {
      //   console.log('NOT Authed, redirecting..');
      //   next('/signin');
      // }
    },
  },

  {
    path: '/email',
    name: 'Email',
    component: () => import(/* webpackChunkName: "email" */ '@/views/Email.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER Email:', store.state.user);
      next();
      // if (store.state.user != null) {
      //   next('/account');
      // } else {
      //   console.log('NOT Authed, redirecting..');
      //   next('/signin');
      // }
    },
  },

  {
    path: '/sms',
    name: 'SMS',
    component: () => import(/* webpackChunkName: "sms" */ '@/views/SMS.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER SMS:', store.state.user);
      next();
      // if (store.state.user != null) {
      //   next('/account');
      // } else {
      //   console.log('NOT Authed, redirecting..');
      //   next('/signin');
      // }
    },
  },

  {
    path: '/page',
    name: 'Page',
    component: () => import(/* webpackChunkName: "page" */ '@/views/Page.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER Page:', store.state.user);
      next();
      // if (store.state.user != null) {
      //   next('/account');
      // } else {
      //   console.log('NOT Authed, redirecting..');
      //   next('/signin');
      // }
    },
  },

  {
    path: '/push-notifications',
    name: 'PushNotifications',
    component: () => import(/* webpackChunkName: "push_notification" */ '@/views/PushNotifications.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER PushNotifications:', store.state.user);
      if (store.state.user != null) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/heartbeats',
    name: 'Heartbeats',
    component: () => import(/* webpackChunkName: "heartbeats" */ '@/views/Heartbeats.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER Heartbeats:', store.state.user);
      if (store.state.user != null) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  // {
  //   path: "/signup",
  //   name: "UserSignUp",
  //   component: () => import(/* webpackChunkName: "signup" */ "@/views/UserSignUp.vue"),
  //   beforeEnter: (to, from, next) => {
  //     console.log("BEFORE ENTER SignUP:", store.state.user);
  //     if (store.state.user != null) {
  //       next("/account");
  //     } else {
  //       next();
  //     }
  //   }
  // },

  {
    path: '/signin',
    name: 'UserSignIn',
    component: () => import(/* webpackChunkName: "signin" */ '@/views/UserSignIn.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER SignIN:', store.state.user);
      if (store.state.user != null) {
        next('/account');
      } else {
        next();
      }
    },
  },

  // {
  //   path: "/manage-account",
  //   name: "UserManageAccount",
  //   component: () => import(/* webpackChunkName: "manage_account" */ "@/views/UserManageAccount.vue"),
  //   beforeEnter: (to, from, next) => {
  //     console.log("BEFORE ENTER ManageAccount:", store.state.user);
  //     next();
  //   }
  // },

  // {
  //   path: "/verify-email",
  //   name: "UserVerifyEmail",
  //   component: () => import(/* webpackChunkName: "verify_email" */ "@/views/UserVerifyEmail.vue"),
  //   beforeEnter: (to, from, next) => {
  //     console.log("BEFORE ENTER VerifyEmail:", store.state.user);
  //     next();
  //   }
  // },

  // {
  //   path: "/reset-pwd",
  //   name: "UserResetPassword",
  //   component: () => import(/* webpackChunkName: "reset_pwd" */ "@/views/UserResetPassword.vue"),
  //   beforeEnter: (to, from, next) => {
  //     console.log("BEFORE ENTER ResetPassword:", store.state.user);
  //     next();
  //   }
  // },

  // {
  //   path: "/change-pwd",
  //   name: "UserChangePassword",
  //   component: () => import(/* webpackChunkName: "change_pwd" */ "@/views/UserChangePassword.vue"),
  //   beforeEnter: (to, from, next) => {
  //     console.log("BEFORE ENTER ChangePassword:", store.state.user);
  //     next();
  //   }
  // },

  // {
  //   path: "/delete-account",
  //   name: "UserDeleteAccount",
  //   component: () => import(/* webpackChunkName: "delete_account" */ "@/views/UserDeleteAccount.vue"),
  //   beforeEnter: (to, from, next) => {
  //     console.log("BEFORE ENTER DeleteAccount:", store.state.user);
  //     if (store.state.user == null) {
  //       console.log("NOT Authed, redirecting..");
  //       next("/signin");
  //     } else {
  //       next();
  //     }
  //   }
  // },

  {
    path: '/account',
    name: 'UserAccount',
    component: () => import(/* webpackChunkName: "account" */ '@/views/UserAccount.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER ACCOUNT:', store.state.user);
      if (store.state.user == null) {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      } else {
        next();
      }
    },
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from) => {
//   console.log(to, from);
//   // ...
//   // explicitly return false to cancel the navigation
//   // return false;
// });

export default router;
