<!-- PWAPrompt.vue -->
<template>
  <div v-if="shown">
    Add app to home screen? {{ getPWADisplayMode() }}

    <button @click="installPWA">Install!</button>

    <button @click="dismissPrompt">No, thanks</button>
  </div>
</template>

<script>
// [beforeinstallprompt] every time the app is ready to become pwa
// [DISPLAY_MODE] 1:1 [page_view] (only in "created" lifecycle)
// [appinstalled] 1:1 [DISPLAY_MODE_CHANGED]
// [pwa_installed] instaled from our PWAPrompt (instead of the browser install prompt)
// the diff #appinstalled - #pwa_installed is the number of times users installed the app withoutu our prompt (say browser install prompt in omnibox)

import { gaLogger } from '@/services/firebase';

export default {
  data: () => ({
    shown: false,
  }),

  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.installEvent = e;
      this.shown = true;
      console.log(`'beforeinstallprompt' event was fired.`);
      gaLogger('beforeinstallprompt');
    });

    window.addEventListener('appinstalled', (e) => {
      // Hide the app-provided install promotion
      this.shown = false;
      // Clear the deferredPrompt so it can be garbage collected
      this.installEvent = null;
      // Optionally, send analytics event to indicate successful install
      console.log('PWA was installed', e);
      gaLogger('appinstalled');
    });

    window.matchMedia('(display-mode: standalone)').addEventListener('change', (evt) => {
      let displayMode = 'browser';
      if (evt.matches) {
        displayMode = 'standalone';
      }
      // Log display mode change to analytics
      console.log('pwa_display_mode_changed', displayMode);
      gaLogger('pwa_display_mode_changed', { value: displayMode });
    });
  },
  created() {
    console.log('display mode is', this.getPWADisplayMode());
    gaLogger('pwa_display_mode', { value: this.getPWADisplayMode() });
  },
  methods: {
    dismissPrompt() {
      this.shown = false;
    },

    installPWA() {
      this.installEvent.prompt();
      this.installEvent.userChoice.then((choice) => {
        console.log('choice was:', choice);
        this.dismissPrompt(); // Hide the prompt once the user's clicked
        if (choice.outcome === 'accepted') {
          // Do something additional if the user chose to install
          console.log('User accepted the pwa prompt and installed the app.');
          gaLogger('pwa_installed');
        } else {
          // Do something additional if the user declined
          console.log('User rejected the pwa prompt.');
          gaLogger('pwa_installation_dismissed');
        }
        this.installEvent = null;
      });
    },

    getPWADisplayMode() {
      const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
      if (document.referrer.startsWith('android-app://')) {
        return 'twa';
      } else if (navigator.standalone || isStandalone) {
        return 'standalone';
      }
      return 'browser';
    },
  },
};
</script>
