/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('App is being served from cache by a service worker!');
    },
    registered(registration) {
      console.log('Service worker has been registered.');
      setInterval(() => {
        console.log('Checking for updates...');
        registration.update();
      }, 5 * 1000); // every 5 seconds
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      // console.log('About to clean cache.');
      // // new content clear cache so user gets the new version
      // caches.keys().then((cacheNames) => {
      //   cacheNames.forEach((cacheName) => {
      //     caches.delete(cacheName);
      //   });
      // });
      console.log('New content is downloading.');
    },
    // updated() {
    //   console.log('New content is available; please refresh.');
    // },
    updated(registration) {
      console.log('New content is available; please refresh.');
      document.dispatchEvent(new CustomEvent('swUpdated', { detail: registration }));
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
