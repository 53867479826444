<template>
  <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">{{ project_name }} - {{ app_version }}</router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <!-- <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Link</a>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
          </li> -->
          <li class="nav-item">
            <router-link class="nav-item nav-link" to="/upload">Upload</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-item nav-link" to="/email">Email</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-item nav-link" to="/sms">SMS</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-item nav-link" to="/page">Page</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-item nav-link" to="/push-notifications">PushNotifications</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-item nav-link" to="/heartbeats">Heartbeats</router-link>
          </li>
        </ul>
        <router-link class="nav-item nav-link" to="/signin" v-if="!this.$store.state.user">Sign in</router-link>
        <!-- <router-link class="nav-item nav-link" to="/signup" v-if="!this.$store.state.user">Sign up</router-link> -->

        <form class="d-flex">
          <!-- <router-link
            class="nav-item nav-link"
            to="#"
            v-if="this.$store.state.user"
            @click.prevent="redirect_to_account">
            Account
          </router-link>
          <router-link class="nav-item nav-link" to="#" v-if="this.$store.state.user" event @click.prevent="logout">
            Logout
          </router-link>
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
          <button class="btn btn-outline-success" type="submit">Search</button> -->
          <button
            class="btn btn-primary"
            type="submit"
            v-if="this.$store.state.user"
            @click.prevent="redirect_to_account">
            Account
          </button>
          &nbsp;
          <button class="btn btn-danger" type="submit" v-if="this.$store.state.user" @click.prevent="logout">
            Logout
          </button>
        </form>
      </div>
    </div>
  </nav>
</template>

<script>
import { auth, signOut } from '@/services/firebase';

// needed for collapse navbar
import 'bootstrap';

export default {
  name: 'Navigation',
  props: ['project_name'],
  data() {
    return {
      app_version: null,
    };
  },
  created() {
    this.app_version = process.env.VUE_APP_VERSION;
  },
  methods: {
    logout: function () {
      var self = this;
      signOut(auth).then(() => {
        self.$router.push('/signin');
      });
    },
    redirect_to_account: function () {
      this.$router.push('/account');
    },
    // bg_based_on_account_type: function(){
    //     if(this.$store.state.account_type == "expert"){
    //         return "bg-dark"
    //     }
    //     if(this.$store.state.account_type == "client"){
    //         return "bg-primary"
    //     }
    // }
  },
};
</script>
