<template>
  <div class="container">
    <div class="row m-1 mt-3">
      <div class="col-md-6">
        <h3>Welcome!</h3>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src

  export default {};
</script>
