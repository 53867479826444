<template>
  <div>
    <!-- <div>{{ refreshing }} - {{ registration }} -/= {{ updateExists }}</div> -->
    <!-- <div v-if="updateExists" class="fw-bold bg-warning text-dark p-3">
    <center>
      An update is available
      <button class="btn btn-primary btn-block" type="button" @click="refreshApp">Please Update</button>
    </center>
  </div> -->
  </div>
</template>

<script>
  export default {
    props: ['alert_message'],
    data() {
      return {
        // refresh variables
        refreshing: false,
        registration: null,
        updateExists: false,
      };
    },
    methods: {
      // Store the SW registration so we can send it a message
      // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
      // To alert the user there is an update they need to refresh for
      updateAvailable(event) {
        this.registration = event.detail;
        this.updateExists = true;

        // difference between this and ServiceWorkerPleaseUpdate
        // alert(this.alert_message);
        console.log(this.alert_message);
        this.refreshApp();
      },

      // Called when the user accepts the update
      refreshApp() {
        this.updateExists = false;
        // Make sure we only send a 'skip waiting' message if the SW is waiting
        if (!this.registration || !this.registration.waiting) return;
        // send message to SW to skip the waiting and activate the new SW
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      },
    },
    created() {
      // Listen for our custom event from the SW registration
      document.addEventListener('swUpdated', this.updateAvailable, { once: true });

      // Prevent multiple refreshes
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        // Here the actual reload of the page occurs
        window.location.reload(true);
      });
    },
  };
</script>

<style></style>
